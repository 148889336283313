body.login-page {
    width: 400px;
    margin: 0 auto;
}

.page-container.horizontal-menu header.navbar .navbar-brand{padding: 10px}

a:hover{cursor: pointer;}

.breadcrumb .bc-3{font-size: 14px;}

ul.login-flags{list-style-type: none; padding-left: 0px;}

.login-flags li.flags{padding:20px 20px;}

.login-flags span a{
  display: inline;
  height: 16px;
  margin: 0 3px;
  padding: 0 0 0 30px;
  overflow: hidden;
  text-indent: 50px;
  width: 24px;
  color:#fff;
}

.login-page .login-content  a{ color:#000; }

.login-flags a:hover{color:#545454}

ul.navbar-right > li i.haiti {
   background-image: url("../images/flags/ht.png");
   background-repeat: no-repeat;
   background-size: 26px 16px;
   padding: 0px 40px 0px 0px;
   position: relative;
   top: 1px;
}

ul.navbar-right li ul li i.english {
   background-image: url("../images/flags/en.png");
   background-repeat: no-repeat;
   background-size: 26px 16px;
   padding: 0px 40px 0px 0px;
   position: relative;
   top: 1px;
}

ul.navbar-right li ul li i.french {
   background-image: url("../images/flags/fr.png");
   background-repeat: no-repeat;
   background-size: 26px 16px;
   padding: 0px 40px 0px 0px;
   position: relative;
   top: 1px;
}

.login-flags span a.haiti{
   background-image: url("../images/flags/ht.png");
   background-repeat: no-repeat;
}

.login-flags span a.english {
   background-image: url("../images/flags/en.png");
   background-repeat: no-repeat;
}

.login-flags span a.french {
  background-image: url("../images/flags/fr.png");
  background-repeat: no-repeat;
}

.flag-inactive{
  -webkit-filter: grayscale(1); /* Webkit */
  filter: gray; /* IE6-9 */
  filter: grayscale(1); /* W3C */
}

.flag-active{
    background-color: #7e7e7e;
    color:#000 !important;
}

a.flag-active:hover{
    color:#000 !important;
}

.login-page{
    background-image: url("../images/bg2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
}

.login-content p.description {
    color: #000;
}

.logo-image {
    width: 230px;
    height: 205px;
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    margin: 0 auto;
}

.login-form {
    background-color: rgba(255,255,255,0.7);
}
.login-page .login-header {
    padding-bottom: 0px;
}

table#votersTable th {
    background-color: #0073b7;
    color: #fff;
}

table#votersTable tbody td:first-child {
    background-color: #ff9600;
    color: #000;
}

table#votersTable tbody td {
    background-color: #00c0ef;;
    color: #000;
}

table tbody td { font-size: 13px; }

.export-data .dt-buttons .btn{
    font-size: 22px;
}
table#generalResultsList th, table#generalResultsList td { white-space: nowrap; }

.no-padding{ padding: 0; }

.partierReport > thead > tr > th { color: #000;}

.bar0 {background-color: #07A0C3;}
.bar1 {background-color: #345995;}
.bar2 {background-color: #03CEA4;}
.bar3 {background-color: #EFEA4F;}
.bar4 {background-color: #FF6666;}

.panel-default > .panel-heading {
    background-color: #90CAF9;
}

.table-bordered > thead > tr > th, .panel-primary > .panel-heading {
    background-color: #90CAF9;
    color: #000;
}

.breadcrumb li.active{
    color: #d70000;
}

.form-control{
    border: 1px solid #949494;
}

.form-control:focus {
    border-color: #00339a;
}

.panel-body {
    background-color: #eaeaea;
}

.panel-body-wh-bg{
    background-color: #fff;
}

.page-error-access {
    color: #ff000c;
    text-align: center;
}

.page-error-access .error-symbol {
    font-size: 120px;
}

.page-error-access .error-text {
    padding-bottom: 25px;
    font-size: 16px;
}

.page-error-access .error-text h2 {
    font-size: 45px;
}

.page-error-access .error-text p {
    font-size: 22px;
}

.page-error-access .error-text + hr {
    margin-bottom: 50px;
}

.page-error-access .input-group {
    width: 250px;
    margin: 0 auto;
}

.modal-dialog {
    padding-top: 130px;
}

.margin-top-90{ margin-top:90px; }

.padding-top-50 { padding-top: 50px;}

table#generalResultsList tr:hover{ cursor: pointer;}

table#votersFlagTable th, table#votersFlagTable td { white-space: nowrap; }
table#votersFlagTable .flag-tr{height: 80px;}

table#votersFlagTable div.dataTables_scrollBody table thead{ visibility: hidden;}
